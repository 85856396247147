import { useEffect, useState } from "react"
import { List, Image, Pagination } from "antd"
import UserAvatar from "../components/user/UserAvatar"
import dayjs from "dayjs"
import LocalTimeAgo from "../components/local_time_ago"
import { connect } from "react-redux"
import { Menu as MenuAction } from '../store/global/actions'
import { Link } from "react-router-dom"
import { LinkOutlined } from "@ant-design/icons"
import contentLinkSrc from "../components/content_link_src"
import "./UserFeedBack.css"

function UserFeedback(props) {
    let [data, setData] = useState({})
    let [channel, setCannel] = useState(null)

    const feedbackType = props.match?.params?.type || "APP"

    useEffect(
        () => {
            props.setActiveKey(`FEEDBACK-${feedbackType.toUpperCase()}`)
            const _channel = props.socket.channel("ops:user:feedback", { size: 20, type: feedbackType })
            _channel.join()
            _channel.on("data", (data) => {
                console.log(data)
                setData(data)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, [feedbackType]
    )

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }

    return <div className="page operation user-feed-back">
        <h1>用户反馈</h1>
        <List dataSource={data.entries}
            // grid={{ gutter: 0, column: 4 }}
            itemLayout="horizontal"
            pagination={false}
            style={{ overflowY: "auto", overflowX: "hidden" }}
            renderItem={item => (
                <List.Item key={`report-${item.id}`} className="item">
                    <List.Item.Meta
                        avatar={<UserAvatar nickname={item.user.nickname} size={40} avatar={item.user.avatar} id={item.user.id} vip={item.user.group === "vip"} />}
                        title={<span>{feedbackType == "correction" && <span style={{ color: "orange" }}>【{item.content.category}】</span>} {dayjs(item.created_at).format("YYYY-MM-DD HH:mm")} <LocalTimeAgo date={dayjs(item.created_at).format("YYYY-MM-DD HH:mm")} /></span>}
                        description={<div>
                            {
                                feedbackType == "correction" && <div>
                                    纠错内容：<Link target="_blank" to={contentLinkSrc(item.content.type, item.content.name || item.content.id)}><LinkOutlined /> {item.content?.name}</Link>
                                </div>
                            }  {
                                feedbackType == "ai_search" && <div>
                                    <div>消息id: {item.content.messageId}</div>
                                    <div>类别: {item.content.contentType}</div>
                                    <div>搜索内容: {item.content.searchContent}</div>
                                </div>
                            }
                            <div>联系方式：{item.concat?.way}</div>
                            <div>内容：{item.content.content}</div>
                        </div>}

                    />
                    <div style={{}}>
                        <Image.PreviewGroup>
                            {!!item.content?.images && item.content.images.map(url => <div style={{ display: "flex" }}>
                                <Image style={{ objectFit: "cover" }} width={80} src={url} height={80} />
                            </div>)}
                        </Image.PreviewGroup>
                    </div>
                </List.Item>
            )}
        />

        <Pagination style={{ marginTop: 10 }} onChange={(page, size) => { handlePageTo(page, size) }} total={data?.total_entries} current={data?.page_number} pageSize={data?.page_size || 20} />
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserFeedback)